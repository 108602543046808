import React, {useEffect, useState} from 'react';
import {
  Box,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
} from '@mui/material';
import {Palette} from 'common/utils/ColorUtils';
import sampleText from 'asset/json/sample_text.json';
import {useNavigate, useLocation} from 'react-router-dom';
import {
  MarginBoxComponent,
  TitleComponent,
  BookComponent,
  LayerBoxComponent,
  CenteredButton,
  SingleLineComponent,
  CustomTextField1,
  CustomTextField2,
  CustomTextField3,
} from 'view/common/MobileComponents';
import HeaderView from 'view/common/MobileHeader';
import {useSelector} from 'react-redux';
import FooterView from 'view/common/MobileFooter';
import emojiRegex from 'emoji-regex';
import APIUtils from 'common/utils/APIUtils';
import WarningPopup from 'view/popup/Warning';
import NoticketPopup from 'view/popup/NoTicket2';
import writingSelectOption from 'asset/json/writing_select_option1.json';
import ageSelectOption from 'asset/json/age_select_option1.json';

const WritingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authReducer = useSelector(state => state.authReducer);

  const [sample, setSample] = useState({});
  const [selectValue, setSelectValue] = useState('');
  const [selectOption, setSelectOption] = useState([]);
  const [bodyText, setBodyText] = useState('');
  const [titleText, setTitleText] = useState('');
  const [nameText, setNameText] = useState('');

  const foundAgeIndex = ageSelectOption.findIndex(
    obj => obj == authReducer.grade_in_charge,
  );
  const [ageIndex, setAgeIndex] = useState(
    foundAgeIndex == -1 ? 0 : foundAgeIndex,
  );

  const handleAgeInput = event => {
    setAgeIndex(event.target.value);
  };

  const [writingSelect, setWritingSelect] = useState(0);
  const handleWritingSelectInput = event => {
    setWritingSelect(event.target.value);
  };

  const [classText, setClassText] = useState('');

  const handleClassInput = event => {
    setClassText(event.target.value);
  };

  const [warningVisibility, setWarningVisibility] = useState(false);
  function warningCancel() {
    setWarningVisibility(false);
  }
  function warningConfirm() {
    setWarningVisibility(false);
    if (location.state?.prev == 'mypage') {
      navigate('/mypage', {
        replace: true,
        state: {
          fragmentNum: 3,
        },
      });
    } else {
      navigate('/', {replace: true});
    }
  }
  const [noticketVisibility, setNoticketVisibility] = useState(false);
  function noticketCancel() {
    setNoticketVisibility(false);
  }
  function noticketComplete() {
    setNoticketVisibility(false);
    navigate('/purchase');
  }
  useEffect(() => {
    let dict = {};
    let selectList = [];
    sampleText.forEach((item, index) => {
      dict[item.subject] = item.text;
      selectList.push({value: index, text: item.subject});
    });
    setSample(dict);
    setSelectOption(selectList);
    getPassInfo();
    const preventGoBack = () => {
      history.pushState(null, '', location.href);
      setWarningVisibility(true);
    };
    history.pushState(null, '', location.href);
    window.addEventListener('popstate', preventGoBack);
    return () => {
      // window.removeEventListener('keydown', backspace, true);
      window.removeEventListener('popstate', preventGoBack);
    };
  }, []);
  const handleSelect = event => {
    const dictKey = selectOption[event.target.value].text;

    setSelectValue(event.target.value);
    setBodyText(sample[dictKey]);
    setTitleText(dictKey);
  };
  const handleBody = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 10000) {
        inputVal = inputVal.substring(0, 10000);
      }
      setBodyText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };
  const handleTitle = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 50) {
        inputVal = inputVal.substring(0, 50);
      }
      setTitleText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };
  const handleName = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 50) {
        inputVal = inputVal.substring(0, 50);
      }
      setNameText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };

  function navigateToResult(disabled) {
    if (disabled) {
      alert('학생 이름, 제목, 본문을 모두 입력해야 제출하기가 가능합니다.');
      return;
    }
  }

  function getPassInfo() {
    const passResult = async () => {
      try {
        const response = await APIUtils.PassCheck(authReducer.user_id);
        if (response.status == 200 && response.data.ret_code == 1000) {
          if (response.data.status == 'none') {
            setNoticketVisibility(true);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    passResult();
  }
  function createSampleSelector() {
    if (process.env.REACT_APP_ENV == 'live') {
      return <></>;
    } else {
      return (
        <FormControl
          size="small"
          sx={{
            m: 1,
            minWidth: 200,
            color: Palette.white,
          }}
        >
          <InputLabel
            id="sample-select-label"
            style={{
              color: 'blue',
            }}
          >
            예시 글 가져오기
          </InputLabel>
          <Select
            labelId="sample-select-label"
            id="sample-select"
            onChange={handleSelect}
            value={selectValue}
            label="예시 글 가져오기"
            style={{
              color: Palette.white,
              backgroundColor: Palette.grey1,
            }}
          >
            {selectOption.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  }
  return (
    <Box style={{width: '100%'}}>
      <NoticketPopup
        visibility={noticketVisibility}
        cancelEvent={noticketCancel}
        completeEvent={noticketComplete}
      />
      <WarningPopup
        visibility={warningVisibility}
        cancelEvent={warningCancel}
        confirmEvent={warningConfirm}
      />
      <HeaderView />
      <TitleComponent
        text1="새 글 평가"
        text2="키위티는 타당하고 신뢰도 있는 키위 AI 평가 엔진을 사용합니다."
      />
      <BookComponent>
        <>
          {createSampleSelector()}
          <SingleLineComponent
            stepNumber={'01'}
            text="글을 작성한 학생의 정보를 입력해 주세요."
          />
          <Box>
            <CustomTextField1
              title="이름"
              emptyText={'이름을 입력해 주세요.'}
              inputText={nameText}
              handleInput={handleName}
              isRequired={true}
            />
          </Box>
          <Box>
            <LayerBoxComponent title="학년">
              <Select
                disableUnderline
                fullWidth
                labelId="select-emoji"
                id="emoji"
                variant="filled"
                hiddenLabel
                onChange={handleAgeInput}
                value={ageIndex}
                style={{
                  backgroundColor: '#F7F8FA',
                  border: '1px solid var(--01-gray-07, #E9EBED)',
                  boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
                  height: '2.6rem',
                  textAlign: 'center',
                  fontSize: '1rem',
                }}
              >
                {ageSelectOption.map((value, index) => {
                  return (
                    <MenuItem
                      style={{
                        justifyContent: 'center',
                        fontSize: '1rem',
                      }}
                      key={'key' + index}
                      value={index}
                    >
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
            </LayerBoxComponent>
          </Box>
          <Box>
            <CustomTextField1
              title="반/번호"
              emptyText={'반/번호'}
              inputText={classText}
              handleInput={handleClassInput}
              isRequired={false}
            />
          </Box>
          <Box
            style={{
              height: '1px',
              backgroundColor: '#e9ebed',
              margin: '3rem 0rem',
            }}
          ></Box>
          <SingleLineComponent
            stepNumber={'02'}
            text="평가할 글을 입력해 주세요."
          />
          <Box style={{marginTop: '2.5rem'}} />
          <Box>
            <Box
              style={{
                fontWeight: 'bold',
                color: 'black',
                marginLeft: '0.5rem',
                marginBottom: '1rem',
              }}
            >
              글의 종류<span style={{color: '#35D2BB'}}>*</span>
            </Box>
            <Select
              disableUnderline
              fullWidth
              labelId="select-writing"
              id="wrintingType"
              variant="filled"
              hiddenLabel
              onChange={handleWritingSelectInput}
              value={writingSelect}
              style={{
                backgroundColor: '#F7F8FA',
                border: '0px solid black',
                borderRadius: '0.625rem',
                padding: '0rem 2rem',
                fontSize: '1rem',
              }}
            >
              {writingSelectOption.map((value, index) => {
                return (
                  <MenuItem key={'key' + index} value={index}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Box style={{marginTop: '1.5rem'}} />
          <CustomTextField2
            title="제목"
            emptyText={'글의 제목을 50자 내로 입력해 주세요.'}
            inputText={titleText}
            handleInput={handleTitle}
            maxCount="50"
          />
          <Box style={{marginTop: '1.5rem'}} />
          <CustomTextField3
            title="본문"
            emptyText={`평가할 글을 입력해주세요. 엔터 키[↲] 입력 시 문단이 나뉘었다고 인식합니다.\n문장 부호 외 특수문자/이모지가 포함된 경우, 평가 결과가 정상적으로 출력되지 않을 수 있습니다.`}
            inputText={bodyText}
            handleInput={handleBody}
            maxCount="10000"
          />
          <CenteredButton
            title="제출하기"
            disabled={
              titleText == '' || bodyText.trim() == '' || nameText == ''
            }
            clickEvent={navigateToResult}
          ></CenteredButton>
        </>
      </BookComponent>
      <FooterView />
    </Box>
  );
};
export default WritingPage;
