import {Box, TextField, Button} from '@mui/material';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import React, {useState} from 'react';
import Pagination from 'view/mypage/Pagination';
import APIUtils from 'common/utils/APIUtils';
import TeacherView from 'view/admin/TeacherView1';
import emojiRegex from 'emoji-regex';

export const FragmentView = () => {
  const authReducer = useSelector(state => state.authReducer);
  const [teacherList, setTeacherList] = useState([]);
  const [originalTeacherList, setOriginalTeacherList] = useState([]);
  const countPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [searchText, setSearchText] = useState('');
  const handleSearchText = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 20) {
        inputVal = inputVal.substring(0, 20);
      }
      setSearchText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };

  function paginationClickEvent(page) {
    setCurrentPage(page);
  }
  function getTeacherInfo() {
    const getInfo = async () => {
      try {
        const response = await APIUtils.AdminTeacherList(authReducer.user_id);
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getInfo().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        setOriginalTeacherList(res.data.teacher_list);
      }
    });
  }
  function teacherDelete(teacher_id) {
    const getInfo = async () => {
      try {
        const response = await APIUtils.AdminTeacherDelete(
          authReducer.user_id,
          teacher_id,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    getInfo().then(res => {
      if (res.status == 200 && res.data.ret_code == 1000) {
        getTeacherInfo();
      }
    });
  }
  function getFilterTeacherList() {
    let filteredList = [...originalTeacherList];
    filteredList = filteredList.filter(
      value =>
        value.name.includes(searchText) || value.email.includes(searchText),
    );
    filteredList = filteredList.sort(function (a, b) {
      if (a.user_id == authReducer.user_id) return -1;
      if (b.user_id == authReducer.user_id) return 1;
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    setTeacherList(filteredList);
    setTotalPage(Math.floor((filteredList.length - 1) / countPerPage + 1));
  }
  useEffect(() => {
    getTeacherInfo();
  }, []);
  useEffect(() => {
    getFilterTeacherList();
  }, [originalTeacherList, searchText]);
  return (
    <Box>
      <Box style={{display: 'flex', justifyContent: 'space-between'}}>
        <Box style={{fontWeight: 'bold', fontSize: '1.375rem'}}>
          키위티 계정 관리
        </Box>
        <Box style={{display: 'flex'}}>
          <TextField
            autoComplete="off"
            variant="outlined"
            fullWidth
            label={searchText == '' ? '🔍 이름/계정' : ''}
            InputProps={{
              style: {
                backgroundColor: '#F7F8FA',
                border: '1px solid #E9EBED',
                fontSize: '1rem',
                borderRadius: '0.625rem',
              },
            }}
            InputLabelProps={{
              style: {
                color: '#ADB4BA',
              },
              shrink: false,
            }}
            value={searchText}
            onChange={handleSearchText}
          ></TextField>
        </Box>
      </Box>
      <Box
        style={{
          margin: '3rem 0rem 1rem 0rem',
          backgroundColor: 'white',
          boxShadow: '0px 5px 5px 0px #cdcdcd',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          paddingBottom: '2rem',
        }}
      >
        <Box
          style={{
            color: '#666972',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            margin: '2rem 2rem 0rem 2rem',
          }}
        >
          키위티(선생님)
        </Box>
        <Box
          style={{
            width: '80%',
            marginTop: '1rem',
            display: 'flex',
            justifyContent: 'space-evenly',
            paddingBottom: '0.7rem',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          <Box style={{flex: 1}}>이름</Box>
          <Box style={{flex: 2}}>계정</Box>
          <Box style={{flex: 1}}>인증 번호</Box>
          <Box style={{flex: 1}}>인증 학생 수</Box>
          <Box style={{flex: 1}}>최근 로그인</Box>
          <Box style={{flex: 1}}>그룹 내보내기</Box>
        </Box>
        <Box
          style={{
            backgroundColor: 'black',
            width: '80%',
            height: '1px',
            marginLeft: '3%',
          }}
        ></Box>
        {teacherList
          .filter((value, index) => {
            if (Math.floor(index / countPerPage) + 1 == currentPage) {
              return true;
            }
            return false;
          })
          .map((value, index) => {
            return (
              <TeacherView
                key={'ticket' + index}
                teacher_info={value}
                delete_teacher={teacherDelete}
              />
            );
          })}
      </Box>
      <Pagination
        totalPage={totalPage}
        currentPage={currentPage}
        clickEvent={paginationClickEvent}
      />
    </Box>
  );
};

export default FragmentView;
