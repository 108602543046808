import {createSlice} from '@reduxjs/toolkit';
const initialState = {
  isLogin: false,
  user_id: -1,
  user_name: '',
  user_email: '',
  export_name: '',
  export_email: '',
  grade_in_charge: '',
  path: '',
  user_token: '',
  prevPage: '/',
  title: '',
  text: '',
  student_age: '',
  student_name: '',
  group_name: '',
  is_group_admin: false,
  auth_key: '',
};
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveLogin: (state, action) => {
      state.isLogin = true;
      state.user_id = action.payload.user_id;
      state.user_name = action.payload.user_name;
      state.user_email = action.payload.user_email;
      state.export_name = action.payload.export_name;
      state.export_email = action.payload.export_email;
      state.grade_in_charge = action.payload.grade_in_charge;
      state.path = action.payload.path;
      state.user_token = action.payload.user_token;
      state.group_name = action.payload.group_name;
      state.is_group_admin = action.payload.is_group_admin;
      state.auth_key = action.payload.auth_key;
    },
    modifyAccountInfo: (state, action) => {
      state.export_name = action.payload.export_name;
      state.export_email = action.payload.export_email;
      state.grade_in_charge = action.payload.grade_in_charge;
    },
    removeLogin: (state, action) => {
      state.isLogin = false;
      state.user_id = -1;
      state.user_name = '';
      state.user_email = '';
      state.export_name = '';
      state.export_email = '';
      state.grade_in_charge = '';
      state.path = '';
      state.user_token = '';
      state.prevPage = '/';
      state.title = '';
      state.text = '';
      state.student_age = '';
      state.student_name = '';
      state.group_name = '';
      state.is_group_admin = false;
      state.auth_key = '';
    },
    savePrevPage: (state, action) => {
      state.prevPage = action.payload.prevPage;
    },
    saveText: (state, action) => {
      state.title = action.payload.title;
      state.text = action.payload.text;
      state.student_age = action.payload.student_age;
      state.student_name = action.payload.student_name;
    },
    saveGroup: (state, action) => {
      state.group_name = action.payload.group_name;
    },
    removeGroup: (state, action) => {
      state.group_name = '';
    },
  },
});
export const {
  saveLogin,
  removeLogin,
  modifyAccountInfo,
  savePrevPage,
  saveText,
  saveGroup,
  removeGroup,
} = authSlice.actions;
export default authSlice.reducer;
